import moment from "moment";

export const validate = {
  email: (str) => {
    // ref: https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html
    return !!str.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
  },
  domain: (str) => {
    // ref: https://regexr.com/3au3g
    return !!str.match(
      /(?:[a-z0-9](?:[a-z0-9-_]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    );
  },
  number: (str) => {
    return !!str.match(/[0-9]/g);
  },
};

// function to capitalize the string pro_Trial => Pro Trail
export const toCapitalize = (str) =>
  str.replace(/(^|_)(\w)/g, ($0, $1, $2) => ($1 && " ") + $2.toUpperCase());

export const isFreePlan = (plan) => plan && !plan.isTrial && !plan.isPaid;

export const getFirstTrialPlan = (plans) =>
  plans.find((plan) => plan.isTrial) || null;

export const showPicker = (e) => {
  try {
    // @ts-ignore
    return e?.target?.showPicker();
  } catch (error) {
    console.log("Failed to open calender picker", error);
  }
};

export const isDevMode = () => {
  return process.env.REACT_APP_ENV !== "production";
};

export const dateTimeFormat = (date) => {
  return moment.utc(date).isValid()
    ? moment.utc(date).format("DD/MM/YYYY HH:mm:ss")
    : date;
};

/**
 * Formats an epoch timestamp into a human-readable date string.
 * The output format is "day<suffix> month year" (e.g., "10th Jan 2024").
 *
 * @param {number} epochSeconds - The epoch timestamp in seconds.
 * @returns {string} The formatted date string.
 */
export const getFormattedDate = (epochSeconds) => {
  const date = new Date(epochSeconds * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const suffix = getDaySuffix(day);

  return `${day}${suffix} ${month} ${year}`;
};

/**
 * Determines the appropriate suffix for a given day of the month.
 *
 * @param {number} day - The day of the month.
 * @returns {string} The suffix for the day (e.g., "st", "nd", "rd", "th").
 */
const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th"; // special case for 11th-13th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const isProd = () => process.env.REACT_APP_ENV === "production";

export const downloadFromUrl = (url, filename) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
